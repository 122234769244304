import { useLocalization } from "@shared-ui/localization-context";
import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import * as React from "react";
import { gtConfig } from "src/stores/wizard/config/staticConfigs/car/gt/gtConfig";
import { EGDSSecondaryButton } from "@egds/react-core/button";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSLayoutPosition } from "@egds/react-core/layout-position";
import { Level, liveAnnounce } from "@egds/react-core/utils";

interface GroundTransportSwitchProps {
  onSwapTypeheadLocations: () => void;
  showGTOnly?: boolean;
}

export const GroundTransportSwitch: React.FunctionComponent<GroundTransportSwitchProps> = (props) => {
  const { onSwapTypeheadLocations, showGTOnly } = props;
  const { formatText } = useLocalization();

  const switchA11yText = formatText(gtConfig.switchLocKey);
  const layoutClass = showGTOnly ? "gt-swap-button" : "common-swap-button";

  const actionSwapA11yText = formatText(gtConfig.switchActionLocKey);

  const [switchState, setSwitchState] = React.useState(false);

  const onSwapLocations = () => {
    const liveAnnounceText = switchState ? actionSwapA11yText : `${actionSwapA11yText}.`;
    liveAnnounce(liveAnnounceText, Level.ASSERTIVE);
    setSwitchState(!switchState);
    onSwapTypeheadLocations();
  };

  return (
    <Viewport>
      <ViewSmall>
        <EGDSLayoutPosition
          type="absolute"
          position={{ top: "six", right: "four", zindex: "layer3" }}
          className={`${layoutClass}`}
        >
          <EGDSSecondaryButton
            onClick={onSwapLocations}
            type="button"
            data-testid="swap_vert"
            aria-label={switchA11yText}
          >
            <EGDSIcon id="swap-location" name="swap_vert" size={EGDSIconSize.MEDIUM} className="iconColor" />
          </EGDSSecondaryButton>
        </EGDSLayoutPosition>
      </ViewSmall>
      <ViewLarge>
        <EGDSLayoutPosition type="absolute" position={{ top: "one", zindex: "layer3" }} className={`${layoutClass}`}>
          <EGDSSecondaryButton
            onClick={onSwapLocations}
            type="button"
            data-testid="swap_horiz"
            aria-label={switchA11yText}
          >
            <EGDSIcon id="swap-location" name="swap_horiz" size={EGDSIconSize.MEDIUM} className="iconColor" />
          </EGDSSecondaryButton>
        </EGDSLayoutPosition>
      </ViewLarge>
    </Viewport>
  );
};
