import * as React from "react";
import { observer } from "mobx-react";

import {
  UitkExperimentalButtonTabs as EGDSExperimentalButtonTabs,
  UitkTab as EGDSTab,
  UitkTabsType as EGDSTabsType,
  UitkTabAnchorType as EGDSTabAnchorType,
} from "uitk-react-experimental-button-tabs";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { useLocalization } from "@shared-ui/localization-context";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";

import { LobTitle } from "components/shared/LobTitle/LobTitle";
import { LobSubtitle } from "components/shared/LobSubtitle/LobSubtitle";
import { Travelers } from "components/shared/Travelers/Travelers";
import { TravelersTriggerTypes } from "components/shared/TravelersField/typings";
import { CarSubLOBs } from "stores/wizard/state/typings";

import { CarsFormView } from "../components/CarsFormView/CarsFormView";
import {
  GroundTransportationPWAViewProps,
  GroundTransportPWAView,
} from "../components/GroundTransportPWA/GroundTransportPWAView";
import { WizardCarPWAViewProps } from "../typings";
import { locKeys } from "../components/l10n";
import { CarTypeDropdown } from "../components/vendorOptions/CarTypeDropdown";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { EGDSSticky } from "@egds/react-core/sticky";
import { ViewRow } from "@egds/react-search-experience";

export const WizardCarPWAView: React.FunctionComponent<WizardCarPWAViewProps> = observer(
  ({
    context,
    isAARPChecked,
    isDriverAgeInRange,
    isDiffDropOff,
    onAARPChange,
    onDifferentDropOffSelected,
    onDriverAgeInRange,
    onTabOptionSelected,
    selectedTab,
    setIsDifferentDropOff,
    toggleBookRoundtrip,
    wizardState,
    showGTOnly = false,
    pageHeading,
    setInputsRefs,
    getLocError,
    templateComponent,
    page,
    sticky,
    cookies,
  }) => {
    const { formatText } = useLocalization();
    const [isStickyLayout, setIsStickyLayout] = React.useState(false);
    const isStickyEnabled = Boolean(!!sticky);
    const {
      globalWizardState,
      carWizardState,
      carWizardState: { config: carConfig },
      carWizardState: {
        config: { gtConfig },
      },
    } = wizardState;
    const globalConfig = globalWizardState.config;
    const includeGT = carConfig.includeGT;
    const actionForm =
      selectedTab === CarSubLOBs.RENTAL
        ? carConfig.isCarTrawler
          ? carConfig.form.action.replace("{locale}", context.locale.substr(0, 2))
          : carConfig.form.action
        : gtConfig.form.action;

    const onTabSelect = (clickedTab: CarSubLOBs) => {
      onTabOptionSelected(clickedTab);
    };

    const {
      config: { fmTitleId },
    } = templateComponent;

    const fmId = getFmId(templateComponent);

    const gtProps: GroundTransportationPWAViewProps = {
      carWizardState,
      toggleBookRoundtrip,
      setInputsRefs,
      getLocError,
      gtConfig,
      globalConfig,
      showGTOnly,
    };
    const gtView = <GroundTransportPWAView {...gtProps} />;

    const noNavigationItems = () => (
      <Viewport>
        <ViewSmall />
        <ViewMedium>
          <>
            {!carConfig.isBiasedWizardEmpty && !carConfig.carType && (
              <EGDSLayoutFlex className="carTypeSelect">
                <EGDSLayoutFlexItem>
                  <CarTypeDropdown carWizardState={carWizardState} />
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            )}

            {carWizardState.subLOBState === CarSubLOBs.GROUND_TRANSPORTATION && (
              <EGDSSpacing padding={{ inline: "six" }}>
                <EGDSLayoutFlex alignItems="center" space="six">
                  <EGDSLayoutFlexItem>
                    <Travelers
                      lobState={carWizardState}
                      colSpan={{ medium: 12 }}
                      config={carWizardState.groundTransportationLocationState.config.travelers}
                      triggerType={TravelersTriggerTypes.LINK_BUTTON}
                    />
                  </EGDSLayoutFlexItem>
                </EGDSLayoutFlex>
              </EGDSSpacing>
            )}
          </>
        </ViewMedium>
      </Viewport>
    );

    const carView = (
      <CarsFormView
        templateComponent={templateComponent}
        setInputsRefs={setInputsRefs}
        carWizardState={carWizardState}
        carConfig={carConfig}
        onDriverAgeInRange={onDriverAgeInRange}
        onDifferentDropOffSelected={onDifferentDropOffSelected}
        setIsDifferentDropOff={setIsDifferentDropOff}
        isDriverAgeInRange={isDriverAgeInRange}
        isDiffDropOff={isDiffDropOff}
        getLocError={getLocError}
        onAARPChange={onAARPChange}
        isAARPChecked={isAARPChecked}
        globalConfig={globalConfig}
        page={page}
        stickyLayout={isStickyLayout}
        sticky={isStickyEnabled}
        cookies={cookies}
      />
    );

    const carPWAView = (
      <form
        noValidate
        action={showGTOnly ? gtConfig?.form?.action : actionForm}
        autoComplete="off"
        className="WizardCarPWA"
        id={carConfig.elementId}
        data-fm={fmId}
        data-fm-title-id={fmTitleId}
        data-testid="wizard"
        onSubmit={carWizardState.submit}
      >
        {showGTOnly ? (
          gtView
        ) : (
          <>
            <LobTitle lobState={carWizardState} customTitle={pageHeading} />
            <LobSubtitle lobState={carWizardState} />
            {includeGT ? (
              <EGDSSpacing margin={{ blockstart: "three" }}>
                <EGDSExperimentalButtonTabs
                  selectedTab={carWizardState.subLOBState}
                  tabsType={EGDSTabsType.NATURAL}
                  onTabSelect={onTabSelect}
                  noNavigationItems={noNavigationItems}
                  tabAnchorType={EGDSTabAnchorType.BUTTON}
                  className="carTypeList"
                >
                  <EGDSTab
                    name={CarSubLOBs.RENTAL}
                    displayName={formatText(locKeys.carTabTitle)}
                    id="Rental-cars-transportation"
                    targetURI="carsForm"
                  >
                    {carWizardState.subLOBState === CarSubLOBs.RENTAL && carView}
                  </EGDSTab>
                  <EGDSTab
                    name={CarSubLOBs.GROUND_TRANSPORTATION}
                    displayName={formatText(locKeys.gtTabTitle)}
                    id="Airport-transportation"
                    targetURI="ground-transfers"
                  >
                    {carWizardState.subLOBState === CarSubLOBs.GROUND_TRANSPORTATION && gtView}
                  </EGDSTab>
                </EGDSExperimentalButtonTabs>
              </EGDSSpacing>
            ) : (
              carView
            )}
          </>
        )}
      </form>
    );

    return (
      <Viewport>
        <ViewSmall>{carPWAView}</ViewSmall>
        <ViewLarge>
          {isStickyEnabled ? (
            <div className="WizardCarPWA-sticky">
              <EGDSSticky zIndex={400} onFixed={(fixed) => setIsStickyLayout(fixed)}>
                <ViewRow
                  layout="fullwidth"
                  canvasTheme={isStickyLayout ? "primary" : "none"}
                  paddedViewRow={isStickyLayout}
                >
                  <EGDSSpacing padding={isStickyLayout ? { blockend: "four" } : undefined}>{carPWAView}</EGDSSpacing>
                </ViewRow>
              </EGDSSticky>
            </div>
          ) : (
            <>{carPWAView}</>
          )}
        </ViewLarge>
      </Viewport>
    );
  }
);

export default WizardCarPWAView;
