import * as React from "react";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSCheckbox } from "@egds/react-core/checkbox";
import { useLocalization } from "@shared-ui/localization-context";
import { locKeys } from "../l10n";

export interface BookRoundtripToggleProps {
  toggleBookRoundtrip: () => void;
  roundTripChecked: boolean;
}

export const BookRoundtripToggle: React.FunctionComponent<BookRoundtripToggleProps> = (props) => {
  const { formatText } = useLocalization();

  return (
    <EGDSLayoutGridItem colSpan="all">
      <div>
        <EGDSCheckbox id="book-roundtrip-toggle" onChange={props.toggleBookRoundtrip} checked={props.roundTripChecked}>
          {formatText(locKeys.gtRoundtrip)}
        </EGDSCheckbox>
        <input
          type="hidden"
          name="roundTrip"
          value={props.roundTripChecked.toString()}
          data-testid="book-roundtrip-input"
        />
      </div>
    </EGDSLayoutGridItem>
  );
};
