import * as React from "react";
import { observer } from "mobx-react";

import {
  EGDSLayoutGrid,
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutConditionalGridTrack,
  EGDSLayoutGridItem,
} from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { Viewport, ViewLarge, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { SubmitRow } from "components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { Location } from "components/shared/Location/Location";
import { Dates } from "components/shared/Dates/Dates";
import { TimeDropdown } from "components/shared/TimeDropdown/TimeDropdown";

import { VendorCodes } from "../vendorOptions/VendorCodes";
import { CarsHiddenSearchData } from "../HiddenSearchData/CarsHiddenSearchData";
import { CarTrawlerHiddenSearchData } from "../HiddenSearchData/CarTrawlerHiddenSearchData";
import { CarsFormDefaultViewProps } from "../../typings";
import { locKeys } from "../l10n";
import { DifferentDropOffToggle } from "../DifferentDropOffToggle/DifferentDropOffToggle";
import { DriverAgeInRangeToggle } from "../DriverAgeInRangeToggle";
import DriverAgeInputField from "../DriversAgeInputField";
import { AARPCheckbox } from "../AARPCheckbox";
import { CarTypeDropdown } from "../vendorOptions/CarTypeDropdown";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { Experiment as ExperimentComponent, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { CarAddPWPToggle } from "components/flexComponents/WizardCarPWA/components/CarAddPWPToggle";

export const CarsFormView: React.FunctionComponent<CarsFormDefaultViewProps> = observer((props) => {
  const {
    carWizardState,
    carConfig,
    isDiffDropOff,
    onDifferentDropOffSelected,
    setIsDifferentDropOff,
    isDriverAgeInRange,
    isAARPChecked,
    onDriverAgeInRange,
    onAARPChange,
    setInputsRefs,
    globalConfig,
    page,
    templateComponent,
    stickyLayout,
    sticky,
    cookies,
  } = props;
  const halfWizard = globalConfig?.halfWizard;
  const { pickUpTimeLabel, dropOffTimeLabel } = locKeys;
  const format12hrConfig = carWizardState.config.timeFormats.timeFormat12Hour;
  const { isCarFormValid, updateInvalidFormsState } = carWizardState;
  const mainGridColumns: EGDSLayoutConditionalGridTrack = { small: 2, medium: 12 };
  const locationColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 6, large: stickyLayout ? 3 : 6 };
  const datesColSpan: EGDSLayoutConditionalGridSpan = {
    small: 2,
    medium: 12,
    large: stickyLayout ? 4 : halfWizard ? 12 : 6,
  };
  const timeDropdownColSpan: EGDSLayoutConditionalGridSpan = {
    small: 1,
    medium: 6,
    large: stickyLayout ? 2 : halfWizard ? 6 : 3,
  };
  const couponColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: halfWizard ? 6 : 4 };
  const driversAgeColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 6 };
  const isAgent = cookies?.getCookie("actx") ?? undefined;

  const carsPWPToggle = {
    viewOnSmallAndMediumViewPort: (
      <EGDSLayoutFlex justifyContent="start">
        <EGDSSpacing margin={{ blockstart: "two" }}>
          <div>
            <CarAddPWPToggle wizardState={carWizardState} />
          </div>
        </EGDSSpacing>
      </EGDSLayoutFlex>
    ),
    viewOnLargeViewPort: (
      <EGDSLayoutGridItem colSpan={locationColSpan} colEnd={13}>
        <EGDSLayoutFlexItem>
          <EGDSLayoutFlex justifyContent="end">
            <CarAddPWPToggle wizardState={carWizardState} />
          </EGDSLayoutFlex>
        </EGDSLayoutFlexItem>
      </EGDSLayoutGridItem>
    ),
  };

  if (isCarFormValid) {
    updateInvalidFormsState();
  }

  React.useEffect(() => {
    const VIEWPORT_MAX_SMALL_BREAKPOINT = 575;
    const { innerWidth: viewportSize } = window;

    if (viewportSize > VIEWPORT_MAX_SMALL_BREAKPOINT && !sticky) {
      setIsDifferentDropOff(true);
    } else {
      setIsDifferentDropOff(false);
    }
  }, []);

  const pageName = page ? page.pageName : "unknown";
  const moduleName = halfWizard ? "HalfBiasedWizard" : "FullBiasedWizard";
  const linkName = "CarSearch";
  const sectionName = "SQM";
  const rfrrid = `${pageName}.${moduleName}.${linkName}.${sectionName}`;

  const extraTracking: any = {
    schemaName: "referrer",
    messageContent: {
      referrerId: rfrrid,
      eventType: Action.CLICK,
    },
  };

  return (
    <>
      <Viewport>
        <ViewSmall>
          <>
            {!carConfig.isBiasedWizardEmpty && !carConfig.carType && (
              <CarTypeDropdown carWizardState={carWizardState} />
            )}
          </>
        </ViewSmall>
        <ViewLarge />
        <ViewMedium />
      </Viewport>
      <EGDSSpacing padding={{ blockstart: "three" }}>
        <EGDSLayoutGrid columns={mainGridColumns} space="three">
          {!isCarFormValid && (
            <LobErrorSummary
              locHeadingToken={props.getLocError(false)!}
              locHeadingArgs={carWizardState.moreThanOneError() && [carWizardState.numberOfErrors]}
              locLinkToken={props.getLocError(true)}
              linkClickFocusId="id-roundtrip-error"
              inputErrorRef={carWizardState.errorInputRef}
              colSpan={mainGridColumns as EGDSLayoutConditionalGridSpan}
              setInputsRefs={setInputsRefs}
            />
          )}

          <Location
            setInputsRefs={setInputsRefs}
            originField
            destinationField={false}
            lobState={carWizardState}
            colSpan={locationColSpan}
            globalConfig={globalConfig}
          />
          <Viewport>
            <ViewMedium>
              <>
                {!stickyLayout && (
                  <Location
                    originField={false}
                    destinationField
                    lobState={carWizardState}
                    colSpan={locationColSpan}
                    globalConfig={globalConfig}
                  />
                )}
                {stickyLayout && (
                  <>
                    <Dates
                      lobState={carWizardState}
                      colSpan={datesColSpan}
                      singleDateSelect
                      setInputsRefs={setInputsRefs}
                    />
                    <TimeDropdown
                      labelKey={pickUpTimeLabel}
                      timeValues={carWizardState.config.timeValues}
                      onChange={carWizardState.setPickupTime}
                      value={carWizardState.pickupTime}
                      invalidKey={carWizardState.timeStartInvalidKey}
                      colSpan={timeDropdownColSpan}
                      use12hr={format12hrConfig}
                      showTimeIcon
                    />
                    <TimeDropdown
                      labelKey={dropOffTimeLabel}
                      timeValues={carWizardState.config.timeValues}
                      onChange={carWizardState.setReturnTime}
                      value={carWizardState.returnTime}
                      colSpan={timeDropdownColSpan}
                      use12hr={format12hrConfig}
                      showTimeIcon
                    />
                    <SubmitRow
                      lobState={carWizardState}
                      rfrr="SearchClick_Cars"
                      globalConfig={globalConfig}
                      extraUISTracking={extraTracking}
                      hasStickyWizard
                    />
                  </>
                )}
              </>
            </ViewMedium>
          </Viewport>
        </EGDSLayoutGrid>
      </EGDSSpacing>
      <Viewport>
        <ViewSmall>
          <>
            <EGDSSpacing margin={{ blockstart: "three" }}>
              <EGDSLayoutGrid columns={mainGridColumns} space="three">
                <DifferentDropOffToggle
                  isDiffDropOff={isDiffDropOff}
                  onDifferentDropOffSelected={onDifferentDropOffSelected}
                  labelToken={carConfig.location.origin.differentDropOffToken}
                />
              </EGDSLayoutGrid>
            </EGDSSpacing>

            <EGDSSpacing margin={{ blockstart: "three" }}>
              <EGDSLayoutGrid columns={mainGridColumns} space="three">
                {isDiffDropOff && (
                  <Location
                    originField={false}
                    destinationField
                    lobState={carWizardState}
                    colSpan={locationColSpan}
                    globalConfig={globalConfig}
                  />
                )}
              </EGDSLayoutGrid>
            </EGDSSpacing>
          </>
        </ViewSmall>
        <ViewMedium />
      </Viewport>
      <EGDSSpacing margin={{ blockstart: "three" }}>
        <EGDSLayoutGrid columns={mainGridColumns} space="three">
          {stickyLayout && (
            <>
              <EGDSSpacing margin={{ blockstart: "three" }}>
                <DifferentDropOffToggle
                  isDiffDropOff={isDiffDropOff}
                  onDifferentDropOffSelected={onDifferentDropOffSelected}
                  labelToken={carConfig.location.origin.differentDropOffToken}
                />
              </EGDSSpacing>
              <EGDSSpacing margin={{ blockstart: "three" }}>
                {isDiffDropOff && (
                  <Location
                    originField={false}
                    destinationField
                    lobState={carWizardState}
                    colSpan={{ large: 4 }}
                    globalConfig={globalConfig}
                  />
                )}
              </EGDSSpacing>
            </>
          )}
          {!stickyLayout && (
            <>
              <Dates lobState={carWizardState} colSpan={datesColSpan} singleDateSelect setInputsRefs={setInputsRefs} />
              <TimeDropdown
                labelKey={pickUpTimeLabel}
                timeValues={carWizardState.config.timeValues}
                onChange={carWizardState.setPickupTime}
                value={carWizardState.pickupTime}
                invalidKey={carWizardState.timeStartInvalidKey}
                colSpan={timeDropdownColSpan}
                use12hr={format12hrConfig}
                showTimeIcon
              />
              <TimeDropdown
                labelKey={dropOffTimeLabel}
                timeValues={carWizardState.config.timeValues}
                onChange={carWizardState.setReturnTime}
                value={carWizardState.returnTime}
                colSpan={timeDropdownColSpan}
                use12hr={format12hrConfig}
                showTimeIcon
              />

              {carConfig.driveAgeCheckbox && (
                <DriverAgeInRangeToggle
                  isDriverAgeInRange={isDriverAgeInRange}
                  onDriverAgeInRange={onDriverAgeInRange}
                  carConfig={carConfig}
                />
              )}
              {carConfig.driveAgeCheckbox && !isDriverAgeInRange && (
                <DriverAgeInputField
                  carWizardState={carWizardState}
                  colSpan={driversAgeColSpan}
                  setInputsRefs={setInputsRefs!}
                />
              )}
              {carConfig.AARPCheckbox && <AARPCheckbox isAARPChecked={isAARPChecked} onAARPChange={onAARPChange} />}
            </>
          )}
        </EGDSLayoutGrid>
      </EGDSSpacing>
      {!stickyLayout && carConfig.showVendorOptions && (
        <VendorCodes
          carWizardState={carWizardState}
          mainColSpan={mainGridColumns}
          colSpan={couponColSpan}
          globalConfig={globalConfig}
        />
      )}
      {carConfig.enableCarsPWPToggle && (
        <ExperimentComponent name="eps_cars_loyalty_toggle">
          <ExperimentControl />
          <ExperimentVariant bucket={1}>
            {isAgent && (
              <ExperimentComponent name="Delta_BSA_Loyalty_Toggle">
                <ExperimentControl />
                <ExperimentVariant bucket={1}>
                  <Viewport>
                    <ViewSmall>{carsPWPToggle.viewOnSmallAndMediumViewPort}</ViewSmall>
                    <ViewMedium>{carsPWPToggle.viewOnSmallAndMediumViewPort}</ViewMedium>
                    <ViewLarge>{carsPWPToggle.viewOnLargeViewPort}</ViewLarge>
                  </Viewport>
                </ExperimentVariant>
              </ExperimentComponent>
            )}
            {!isAgent && (
              <Viewport>
                <ViewSmall>{carsPWPToggle.viewOnSmallAndMediumViewPort}</ViewSmall>
                <ViewMedium>{carsPWPToggle.viewOnSmallAndMediumViewPort}</ViewMedium>
                <ViewLarge>{carsPWPToggle.viewOnLargeViewPort}</ViewLarge>
              </Viewport>
            )}
          </ExperimentVariant>
        </ExperimentComponent>
      )}
      {!stickyLayout && (
        <SubmitRow
          lobState={carWizardState}
          rfrr="SearchClick_Cars"
          globalConfig={globalConfig}
          extraUISTracking={extraTracking}
        />
      )}
      {!carConfig.isCarTrawler ? (
        <CarsHiddenSearchData lobState={carWizardState} templateComponent={templateComponent} pageName={pageName} />
      ) : (
        <CarTrawlerHiddenSearchData
          lobState={carWizardState}
          templateComponent={templateComponent}
          pageName={pageName}
        />
      )}
    </>
  );
});
