// External libraries
import * as React from "react";
import { observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";

// UITK
import { EGDSLayoutFlexItem, EGDSLayoutFlexItemProps } from "@egds/react-core/layout-flex";
import { TypeaheadSelection } from "@egds/react-core/typeahead";

// Components
import { DestinationFieldConfig } from "src/stores/wizard/config/typings";
import { LocationFieldConfig } from "src/stores/wizard/config";
import { ContextStore } from "bernie-plugin-mobx";
import { Location } from "src/stores/wizard/state/global/location/typings";
import { GroundTransportationLocationState } from "stores/wizard/state/car/GroundTransportationLocationState";
import { LocationField } from "components/shared/LocationField/LocationField";

export interface GroundTransportLocationFieldProps {
  fieldConfig: DestinationFieldConfig | LocationFieldConfig;
  context: ContextStore;
  fieldInvalidKey: string;
  location: Location;
  onLocationFieldUpdate: (selection: TypeaheadSelection) => void;
  setInputsRefs?: (ref: React.RefObject<HTMLInputElement>) => void;
  groundTransportLocationState: GroundTransportationLocationState;
  maxWidth?: EGDSLayoutFlexItemProps["maxWidth"];
  minWidth?: EGDSLayoutFlexItemProps["minWidth"];
}

export const GroundTransportLocationField: React.FunctionComponent<GroundTransportLocationFieldProps> = observer(
  (props) => {
    const {
      fieldConfig,
      context,
      onLocationFieldUpdate,
      fieldInvalidKey,
      location,
      setInputsRefs,
      groundTransportLocationState,
      maxWidth,
      minWidth,
    } = props;
    const { formatText } = useLocalization();

    fieldConfig.essAdapterConfig.regionId = groundTransportLocationState.regionId;

    return (
      <EGDSLayoutFlexItem maxWidth={maxWidth} minWidth={minWidth}>
        <div>
          <LocationField
            context={context}
            onUpdate={onLocationFieldUpdate}
            icon={fieldConfig.icon}
            name={fieldConfig.inputName}
            label={formatText(fieldConfig.labelToken)}
            typeaheadPlaceholder={formatText(fieldConfig.typeaheadLabelToken!)}
            invalid={formatText(fieldInvalidKey)}
            value={location.value}
            essAdapterConfig={fieldConfig.essAdapterConfig}
            stid={`location-field-${fieldConfig.inputName}`}
            config={fieldConfig}
            location={location}
            setInputsRefs={setInputsRefs}
          />
        </div>
      </EGDSLayoutFlexItem>
    );
  }
);
