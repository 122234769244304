import * as React from "react";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSNumberInput } from "@egds/react-core/input";
import { DriversAgeInputFieldProps } from "../typings";
import { observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";
import { locKeys } from "./l10n";

const DriverAgeInputField: React.FC<DriversAgeInputFieldProps> = observer(
  ({ carWizardState, colSpan, setInputsRefs }) => {
    const { formatText } = useLocalization();
    const { updateDriversAgeInput, driversAge, driversAgeInvalidKey } = carWizardState;
    const inputRef = React.createRef<HTMLInputElement>();
    const { driversAgePlaceholder } = locKeys;

    React.useEffect(() => {
      setInputsRefs(inputRef);
    });

    return (
      <EGDSLayoutGridItem colSpan={colSpan}>
        <div>
          <EGDSNumberInput
            id="drivers-age"
            name="drivers-age"
            hasVisibleLabel={false}
            label={formatText(driversAgePlaceholder)}
            onChange={updateDriversAgeInput}
            value={driversAge}
            placeholder={formatText(driversAgePlaceholder)}
            invalid={formatText(driversAgeInvalidKey)}
            domRef={inputRef}
          />
          <input type="hidden" name="age" value={driversAge} data-testid="drivers-age-input" />
        </div>
      </EGDSLayoutGridItem>
    );
  }
);

export default DriverAgeInputField;
