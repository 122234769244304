import * as React from "react";
import { observer } from "mobx-react";
import { Dates } from "src/components/shared/Dates/Dates";
import { TimeDropdown } from "src/components/shared/TimeDropdown/TimeDropdown";
import { locKeys } from "../l10n";
import { CarWizardState } from "src/stores/wizard/state";
import { EGDSLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";

export interface BookRoundtripProps {
  carWizardState: CarWizardState;
  datesColSpan: EGDSLayoutConditionalGridSpan;
  timeColSpan: EGDSLayoutConditionalGridSpan;
  setInputsRefs?: (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => void;
}

export const BookRoundtrip: React.FunctionComponent<BookRoundtripProps> = observer((props) => {
  const { carWizardState, datesColSpan, timeColSpan, setInputsRefs } = props;
  const format12hrConfig = carWizardState.config.timeFormats.timeFormat12Hour;

  return (
    <>
      <Dates
        lobState={carWizardState}
        singleDate
        additionalSingleDate
        colSpan={datesColSpan}
        startId="d2"
        setInputsRefs={setInputsRefs}
      />
      <TimeDropdown
        labelKey={locKeys.gtDepartureTime}
        timeValues={carWizardState.config.gtConfig.timeValues}
        onChange={carWizardState.setReturnTime}
        value={carWizardState.returnTime}
        colSpan={timeColSpan}
        use12hr={format12hrConfig}
        invalidKey={carWizardState.timeEndInvalidKey}
        showTimeIcon
      />
    </>
  );
});
