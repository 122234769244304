import { Experiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { Dates } from "components/shared/Dates/Dates";
import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { SubmitRow } from "components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { TimeDropdown } from "components/shared/TimeDropdown/TimeDropdown";
import { Travelers } from "components/shared/Travelers/Travelers";
import { TravelersTriggerTypes } from "components/shared/TravelersField/typings";
import { observer } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { GlobalConfig } from "stores/wizard/config";
import { GtConfig } from "stores/wizard/config/staticConfigs/car/typings";
import { CarWizardState } from "stores/wizard/state/car/CarWizardState";
import {
  EGDSLayoutConditionalGridTrack,
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutGrid,
} from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { locKeys } from "../l10n";
import { BookRoundtrip } from "./BookRoundtrip";
import { BookRoundtripToggle } from "./BookRoundtripToggle";
import { GTHiddenSearchData } from "./GTHiddenSearchData";
import { GroundTransportLocation } from "./Location/GroundTransportLocation";

export interface GroundTransportationPWAViewProps {
  carWizardState: CarWizardState;
  toggleBookRoundtrip: () => void;
  setInputsRefs?: (ref: React.RefObject<HTMLInputElement>) => void;
  getLocError: (isLink: boolean) => string | undefined;
  gtConfig: GtConfig;
  globalConfig?: GlobalConfig;
  showGTOnly: boolean;
}

export const GroundTransportPWAView = observer((props: GroundTransportationPWAViewProps) => {
  const {
    carWizardState,
    carWizardState: {
      pickupTime,
      isGroundTransportFormValid,
      returnTime,
      groundTransportationLocationState,
      updateInvalidFormsState,
    },
    setInputsRefs,
    toggleBookRoundtrip,
    showGTOnly,
  } = props;

  const roundTrip = props.carWizardState.roundTrip;

  const { gtArrivalTime, gtDepartureTime, gtArrivalDate, gtDepartureDate } = locKeys;
  const { isOriginAirport, updateFieldsFromQueryParams, airportCode, hotelId } = groundTransportationLocationState;
  const format12hrConfig = carWizardState.config.timeFormats.timeFormat12Hour;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const mainGridLayout: EGDSLayoutConditionalGridTrack = { small: 1, medium: 12 };

  const errorSummaryGridColumns: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12 };
  const travelersColSpan: EGDSLayoutConditionalGridSpan = showGTOnly
    ? { small: 1, medium: 4 }
    : { small: 1, large: 12 };
  const locationColSpan: EGDSLayoutConditionalGridSpan = showGTOnly
    ? { small: 1, medium: 12 }
    : { small: 1, medium: 12, large: 6 };
  const datesColSpan: EGDSLayoutConditionalGridSpan = showGTOnly
    ? { small: 1, medium: 4 }
    : { small: 1, medium: 6, large: 3 };
  const timeDropdownColSpan: EGDSLayoutConditionalGridSpan = showGTOnly
    ? { small: 1, medium: 4 }
    : { small: 1, medium: 6, large: 3 };

  const roundTripDatesColSpan: EGDSLayoutConditionalGridSpan = { small: 1, medium: 6, large: 3 };
  const roundTripTimeColSpan: EGDSLayoutConditionalGridSpan = { small: 1, medium: 6, large: 3 };

  if (isGroundTransportFormValid) {
    updateInvalidFormsState();
  }

  React.useEffect(() => {
    updateFieldsFromQueryParams(queryParams);
    if (showGTOnly) {
      carWizardState.updateGroundTransfersFieldsFromQueryParams(queryParams);
    }
  }, []);

  const dateLabel = isOriginAirport ? gtArrivalDate : gtDepartureDate;
  const timeLabel = isOriginAirport ? gtArrivalTime : gtDepartureTime;
  carWizardState.config.gtConfig.date.start.labelToken = dateLabel;

  return (
    <>
      <EGDSSpacing margin={{ blockstart: "one" }}>
        <EGDSLayoutGrid columns={mainGridLayout} space="three">
          <>
            {!isGroundTransportFormValid && (
              <LobErrorSummary
                locHeadingToken={props.getLocError(false)!}
                locHeadingArgs={carWizardState.moreThanOneError() && [carWizardState.numberOfErrors]}
                locLinkToken={props.getLocError(true)}
                linkClickFocusId="id-roundtrip-error"
                inputErrorRef={carWizardState.errorInputRef}
                colSpan={errorSummaryGridColumns}
                setInputsRefs={setInputsRefs}
              />
            )}
            {!showGTOnly && (
              <Viewport>
                <ViewSmall>
                  <Travelers
                    lobState={carWizardState}
                    colSpan={travelersColSpan}
                    config={props.gtConfig.travelers}
                    triggerType={TravelersTriggerTypes.LINK_BUTTON}
                  />
                </ViewSmall>
                <ViewMedium />
              </Viewport>
            )}
            <GroundTransportLocation
              groundTransportationLocationState={groundTransportationLocationState}
              colSpan={locationColSpan}
              setInputsRefs={setInputsRefs}
              lobState={carWizardState}
              showGTOnly={showGTOnly}
            />
            <Experiment name="Remove_Same_Day_GT">
              <ExperimentControl>
                <Dates lobState={carWizardState} singleDate colSpan={datesColSpan} setInputsRefs={setInputsRefs} />
              </ExperimentControl>
              <ExperimentVariant bucket={1}>
                <Dates
                  lobState={carWizardState}
                  singleDate
                  colSpan={datesColSpan}
                  setInputsRefs={setInputsRefs}
                  validStartDateOffset={2}
                />
              </ExperimentVariant>
            </Experiment>

            <TimeDropdown
              labelKey={timeLabel}
              timeValues={carWizardState.config.gtConfig.timeValues}
              onChange={carWizardState.setPickupTime}
              value={pickupTime}
              invalidKey={carWizardState.timeStartInvalidKey}
              colSpan={timeDropdownColSpan}
              use12hr={format12hrConfig}
              showTimeIcon
            />
            {showGTOnly && (
              <Travelers lobState={carWizardState} colSpan={travelersColSpan} config={props.gtConfig.travelers} />
            )}
            {isOriginAirport && (
              <BookRoundtripToggle toggleBookRoundtrip={toggleBookRoundtrip} roundTripChecked={roundTrip} />
            )}
            {isOriginAirport && roundTrip && (
              <BookRoundtrip
                carWizardState={carWizardState}
                datesColSpan={roundTripDatesColSpan}
                timeColSpan={roundTripTimeColSpan}
                setInputsRefs={setInputsRefs}
              />
            )}
          </>
        </EGDSLayoutGrid>
      </EGDSSpacing>
      <SubmitRow lobState={props.carWizardState} globalConfig={props.globalConfig} />
      <GTHiddenSearchData
        pickUpTime={pickupTime}
        dropOffTime={roundTrip ? returnTime : ""}
        direction={isOriginAirport}
        airportCode={airportCode}
        hotelId={hotelId}
        regionId={groundTransportationLocationState.regionId}
      />
    </>
  );
});
