import * as React from "react";
import { observer } from "mobx-react";

import {
  EGDSLayoutGrid,
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";

import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { Location } from "components/shared/Location/Location";
import { Dates } from "components/shared/Dates/Dates";
import { TimeDropdown } from "components/shared/TimeDropdown/TimeDropdown";
import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";
import { OffsetGridItem } from "components/shared/OffsetGridItem/OffsetGridItem";

import { CarsFormViewProps } from "../../typings";
import { locKeys } from "../l10n";
import { DifferentDropOffToggleButton } from "../DifferentDropOffToggle/DifferentDropOffToggleButton";
import { CarsHotwireHiddenSearchData } from "../HiddenSearchData/CarsHotwireHiddenSearchData";
import { useMultiLOBWizardRegion } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/WizardRegionContextProvider/MultiLOBWizardRegionContext";

export const CarsFormHotwireView: React.FunctionComponent<CarsFormViewProps> = observer((props) => {
  const {
    carWizardState,
    isDiffDropOff,
    onDifferentDropOffSelected,
    setIsDifferentDropOff,
    setInputsRefs,
    globalConfig,
    carConfig,
    templateComponent,
  } = props;

  const { pickUpTimeLabel, dropOffTimeLabel } = locKeys;
  const { timeFormats, timeValues, timeCannonical } = carWizardState.config;

  const format12hrConfig = timeFormats.timeFormat12Hour;
  const { isCarFormValid, updateInvalidFormsState } = carWizardState;

  const { isMultiLOB } = useMultiLOBWizardRegion();

  const gridSpacing = isMultiLOB ? "four" : "three";
  const colSpacingForLarge = isMultiLOB ? 6 : 4;

  const mainGridColumns: EGDSLayoutConditionalGridTrack = { small: 2, medium: 12, large: 12 };
  const locationColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12, large: colSpacingForLarge };
  const datesColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12, large: colSpacingForLarge };
  const timeDropdownColSpan: EGDSLayoutConditionalGridSpan = { small: 1, medium: 6, large: isMultiLOB ? 3 : 2 };
  const submitGridcolumns: EGDSLayoutConditionalGridTrack = { small: 1, medium: 12, large: 12 };
  const submitColSpan: EGDSLayoutConditionalGridSpan = { small: 1, medium: 12, large: 4 };
  if (isCarFormValid) {
    updateInvalidFormsState();
  }

  React.useEffect(() => {
    const VIEWPORT_MAX_SMALL_BREAKPOINT = 575;
    const { innerWidth: viewportSize } = window;

    if (viewportSize > VIEWPORT_MAX_SMALL_BREAKPOINT) {
      setIsDifferentDropOff(true);
    } else {
      setIsDifferentDropOff(false);
    }
  }, []);

  return (
    <>
      <EGDSSpacing padding={{ blockstart: "three" }}>
        <EGDSLayoutGrid columns={mainGridColumns} space={gridSpacing}>
          {!isCarFormValid && (
            <LobErrorSummary
              locHeadingToken={props.getLocError(false)!}
              locHeadingArgs={carWizardState.moreThanOneError() && [carWizardState.numberOfErrors]}
              locLinkToken={props.getLocError(true)}
              linkClickFocusId="id-roundtrip-error"
              inputErrorRef={carWizardState.errorInputRef}
              colSpan={mainGridColumns as EGDSLayoutConditionalGridSpan}
              setInputsRefs={setInputsRefs}
            />
          )}

          <Location
            setInputsRefs={setInputsRefs}
            originField
            destinationField={false}
            lobState={carWizardState}
            colSpan={locationColSpan}
            globalConfig={globalConfig}
          />
          <Viewport>
            <ViewMedium>
              <Location
                originField={false}
                destinationField
                lobState={carWizardState}
                colSpan={locationColSpan}
                globalConfig={globalConfig}
              />
            </ViewMedium>
          </Viewport>
        </EGDSLayoutGrid>
      </EGDSSpacing>
      <Viewport>
        <ViewSmall>
          <>
            <EGDSSpacing margin={{ blockstart: "three" }}>
              <EGDSLayoutGrid columns={mainGridColumns} space="three">
                {isDiffDropOff && (
                  <Location
                    originField={false}
                    destinationField
                    lobState={carWizardState}
                    colSpan={locationColSpan}
                    globalConfig={globalConfig}
                  />
                )}
              </EGDSLayoutGrid>
            </EGDSSpacing>
            <EGDSLayoutFlex space="three">
              <DifferentDropOffToggleButton
                isDiffDropOff={isDiffDropOff}
                onDifferentDropOffSelected={onDifferentDropOffSelected}
                labelToken={carConfig.location.origin.differentDropOffToken}
              />
            </EGDSLayoutFlex>
          </>
        </ViewSmall>
        <ViewMedium />
      </Viewport>
      <EGDSSpacing margin={{ blockstart: "three" }}>
        <EGDSLayoutGrid columns={mainGridColumns} space={gridSpacing}>
          <Dates
            lobState={carWizardState}
            colSpan={datesColSpan}
            singleDateSelect
            setInputsRefs={setInputsRefs}
            useHiddenInputs={false}
          />
          <TimeDropdown
            labelKey={pickUpTimeLabel}
            timeValues={timeValues}
            locKeys={carWizardState.config.locKeys}
            timeCannonical={timeCannonical}
            onChange={carWizardState.setPickupTime}
            value={carWizardState.pickupTime}
            invalidKey={carWizardState.timeStartInvalidKey}
            colSpan={timeDropdownColSpan}
            use12hr={format12hrConfig}
            showTimeIcon={false}
            dataTestId="pickUpTime"
          />
          <TimeDropdown
            labelKey={dropOffTimeLabel}
            timeValues={timeValues}
            locKeys={carWizardState.config.locKeys}
            timeCannonical={timeCannonical}
            onChange={carWizardState.setReturnTime}
            value={carWizardState.returnTime}
            colSpan={timeDropdownColSpan}
            use12hr={format12hrConfig}
            showTimeIcon={false}
            dataTestId="dropOffTime"
          />
        </EGDSLayoutGrid>
      </EGDSSpacing>
      <CarsHotwireHiddenSearchData templateComponent={templateComponent} />
      <EGDSSpacing margin={{ blockstart: "three", blockend: "three" }}>
        <EGDSLayoutGrid columns={submitGridcolumns} space={gridSpacing}>
          {isMultiLOB && <OffsetGridItem colSpan={{ large: 4 }} />}
          <SubmitButton lobState={carWizardState} colSpan={submitColSpan} globalConfig={globalConfig} />
        </EGDSLayoutGrid>
      </EGDSSpacing>
    </>
  );
});
