import * as React from "react";

import { EGDSTertiaryButton } from "@egds/react-core/button";
import { EGDSLayoutPosition } from "@egds/react-core/layout-position";
import { EGDSIcon } from "@egds/react-core/icons";

import { useLocalization } from "@shared-ui/localization-context";

import { DifferentDropOffToggleProps } from "../../typings";

export const DifferentDropOffToggleButton: React.FunctionComponent<DifferentDropOffToggleProps> = ({
  onDifferentDropOffSelected,
  isDiffDropOff,
  labelToken,
}) => {
  const { formatText } = useLocalization();

  return (
    <EGDSLayoutPosition position={{ left: "minus-four", top: "one" }} type="relative">
      <EGDSTertiaryButton
        onClick={onDifferentDropOffSelected}
        type="button"
        data-testid="different-dropoff-location-link-toggle"
      >
        <EGDSIcon name={isDiffDropOff ? "remove" : "add"} />
        {formatText(labelToken)}
      </EGDSTertiaryButton>
    </EGDSLayoutPosition>
  );
};
