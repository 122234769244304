import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { DefaultLocationProps } from "src/components/shared/Location/typings";
import { GroundTransportationLocationState } from "src/stores/wizard/state/car/GroundTransportationLocationState";
import { ACTION_DONE, getTracker } from "src/utils/TrackingUtils";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { TypeaheadSelection } from "@egds/react-core/typeahead";
import { GroundTransportLocationField } from "./GroundTransportLocationField";
import { GroundTransportSwitch } from "./GroundTransportSwitch";

interface GroundTransportationLocationProps extends DefaultLocationProps {
  groundTransportationLocationState: GroundTransportationLocationState;
  showGTOnly?: boolean;
}

export const GroundTransportLocation: React.FunctionComponent<GroundTransportationLocationProps> = inject(
  "context",
  "analytics"
)(
  observer(
    ({ context, groundTransportationLocationState, colSpan, setInputsRefs, lobState, analytics, showGTOnly }) => {
      if (!context) {
        // Check for injected stores
        return null;
      }

      const {
        originConfig,
        location,
        originInvalidKey,
        destinationConfig,
        destinationInvalidKey,
      } = groundTransportationLocationState;

      /* istanbul ignore next */
      const onOriginLocationFieldUpdate = (selection: TypeaheadSelection) => {
        groundTransportationLocationState.updateOriginSelection(selection);
        tracker.trackEvent(originRfrr + ACTION_DONE);
      };

      /* istanbul ignore next */
      const onDestinationLocationFieldUpdate = (selection: TypeaheadSelection) => {
        groundTransportationLocationState.updateDestinationSelection(selection);
        tracker.trackEvent(destinationRfrr + ACTION_DONE);
      };

      const onSwapTypeaheadLocations = () => {
        groundTransportationLocationState.swapTypeaheadLocations();
      };

      const originRfrr = "origin.";
      const destinationRfrr = "destination.";

      const tracker = getTracker(lobState.trackingEnabled, analytics, lobState.moduleName);

      return (
        <EGDSLayoutGridItem colSpan={colSpan}>
          <div className="GroundTransportationLocation">
            <Viewport>
              <ViewSmall>
                <EGDSLayoutFlex direction="column" space="two">
                  <GroundTransportLocationField
                    context={context}
                    fieldConfig={originConfig!}
                    fieldInvalidKey={originInvalidKey}
                    location={location.origin}
                    onLocationFieldUpdate={onOriginLocationFieldUpdate}
                    setInputsRefs={setInputsRefs}
                    groundTransportLocationState={groundTransportationLocationState}
                  />
                  <GroundTransportSwitch onSwapTypeheadLocations={onSwapTypeaheadLocations} showGTOnly={showGTOnly} />
                  <GroundTransportLocationField
                    context={context}
                    fieldConfig={destinationConfig!}
                    fieldInvalidKey={destinationInvalidKey}
                    location={location.destination}
                    onLocationFieldUpdate={onDestinationLocationFieldUpdate}
                    setInputsRefs={setInputsRefs}
                    groundTransportLocationState={groundTransportationLocationState}
                  />
                </EGDSLayoutFlex>
              </ViewSmall>
              <ViewLarge>
                <EGDSLayoutFlex space="two">
                  <GroundTransportLocationField
                    context={context}
                    fieldConfig={originConfig!}
                    fieldInvalidKey={originInvalidKey}
                    location={location.origin}
                    onLocationFieldUpdate={onOriginLocationFieldUpdate}
                    setInputsRefs={setInputsRefs}
                    groundTransportLocationState={groundTransportationLocationState}
                    maxWidth="half_width"
                    minWidth="half_width"
                  />
                  <GroundTransportSwitch onSwapTypeheadLocations={onSwapTypeaheadLocations} showGTOnly={showGTOnly} />
                  <GroundTransportLocationField
                    context={context}
                    fieldConfig={destinationConfig!}
                    fieldInvalidKey={destinationInvalidKey}
                    location={location.destination}
                    onLocationFieldUpdate={onDestinationLocationFieldUpdate}
                    setInputsRefs={setInputsRefs}
                    groundTransportLocationState={groundTransportationLocationState}
                    maxWidth="half_width"
                    minWidth="half_width"
                  />
                </EGDSLayoutFlex>
              </ViewLarge>
            </Viewport>
          </div>
        </EGDSLayoutGridItem>
      );
    }
  )
);
